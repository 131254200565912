<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-toggler v-b-tooltip.hover.v-primary :title="$t('Change Theme')" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <offer-dropdown />
      <a href="https://portal.kombiklimaparca.com/Login" class="go-to-portal-button btn btn-primary ml-2">
        <i class="fal fa-sign-in-alt"></i>
        <span>{{ $t('GoToPortal') }}</span>
      </a>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, VBTooltip } from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from './Locale.vue'
import OfferDropdown from './OfferDropdown.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    VBTooltip,
    Locale,
    OfferDropdown,
    // Navbar Components
    DarkToggler
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
.go-to-portal-button {
  .fa-sign-in-alt {
    font-size: 1.25rem;
    display: none;
  }
  span {
    display: block;
  }

  @media screen and (max-width: 500px) {
    .fa-sign-in-alt {
      display: block;
    }
    span {
      display: none;
    }
  }
}
</style>
