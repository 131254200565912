<template>
  <b-nav-item-dropdown class="dropdown-offer mr-25" menu-class="dropdown-menu-media" right ref="offerDropdown">
    <template #button-content>
      <feather-icon :badge="offerList.length" class="text-body" icon="BriefcaseIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('offer.list') }}
          <span v-if="offerList.length" @click="clearOffer()" class="small text-danger cursor-pointer">
            Temizle
          </span>
        </h4>
        <b-badge pill variant="light-primary"> {{ offerList.length }} {{ $t('offer.product') }} </b-badge>
      </div>
    </li>

    <!-- OfferList Items -->
    <vue-perfect-scrollbar v-if="offerList.length" :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="li">
      <div v-for="item in offerList" :key="`offer_dropdown_${item.product_id}_unit_${item.sales_unit_id}`" class="offer-dropdown-item media">
        <div class="offer-dropdown-item-product-name">
          <div class="text-body">
            <h6>{{ `${$t('shop.product_code_tooltip')}: ${item.product_code}` }}</h6>
            <router-link
              :to="{
                name: 'ProductDetail',
                params: { id: item.product_id }
              }"
              class="mb-0"
            >
              {{ item.brand_name }}<br />{{ item.product_name }}
            </router-link>
          </div>
          <hr />
        </div>
        <div class="offer-dropdown-item-detail">
          <b-img :src="productImage(item)" :alt="item.product_name" rounded width="60px" class="offer-dropdown-item-product-image" @click="openProduct(item.product_id)" />
          <div class="offer-dropdown-item-product-quantity">
            <p v-if="item.sales_unit_id !== item.unit_id" class="small">{{ getStockPieceByUnitID(item) }}</p>
            <div>
              <change-quantity :productId="item.product_id" :unitId="item.unit_id" :others="false" />
            </div>
          </div>
        </div>
        <feather-icon icon="TrashIcon" class="offer-item-remove cursor-pointer text-danger" @click.stop="removeItemFromOffer(item)" />
      </div>
    </vue-perfect-scrollbar>

    <!-- OfferList Footer -->
    <li v-if="offerList.length" class="dropdown-menu-footer px-1 pt-1">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block :to="{ name: 'OfferRequest' }" @click="hideDropdown()">
        {{ $t('offer.go_to_offer_page') }}
      </b-button>
    </li>

    <p v-if="!offerList.length" class="m-0 p-1 text-center">
      {{ $t('offer.empty') }}
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BMedia, BLink, BImg, BButton } from 'bootstrap-vue'
import ChangeQuantity from '@/views/Order/Shop/ChangeQuantity.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { CLEAR_OFFER, REMOVE_OFFER } from '@/store/services/offer-service'
import { SET_NOTIFICATION } from '@/store/services/notification-service'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    VuePerfectScrollbar,
    BButton,
    ChangeQuantity
  },
  directives: {
    Ripple
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false
      }
    }
  },
  computed: {
    ...mapGetters(['offerList', 'getLocale', 'brandLogo'])
  },
  methods: {
    productImage(product) {
      if (product.image_id) {
        return `https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${product.image_id}`
      }
      return this.brandLogo
    },
    removeItemFromOffer(product) {
      this.$store.commit(REMOVE_OFFER, product)
      this.$store.commit(SET_NOTIFICATION, {
        status: true,
        title: this.$t('offer.list'),
        message: this.$t('offer.remove_offer_message')
      })
    },
    hideDropdown() {
      this.$refs.offerDropdown.hide()
    },
    openProduct(id) {
      this.$router.push({
        name: '/Shop/Product',
        params: { id }
      })
    },
    clearOffer() {
      this.$swal({
        title: this.$t('offer.clear_swal.title'),
        html: this.$t('offer.clear_swal.html'),
        icon: 'warning',
        customClass: {
          denyButton: 'btn btn-danger',
          confirmButton: 'btn btn-success mr-2'
        },
        showDenyButton: true,
        confirmButtonText: this.$t('offer.clear_swal.confirm'),
        denyButtonText: this.$t('offer.clear_swal.deny'),
        buttonsStyling: false
      }).then(res => {
        if (res.value) {
          this.$store.commit(CLEAR_OFFER)
          this.$store.commit(SET_NOTIFICATION, {
            status: true,
            title: this.$t('offer.list'),
            message: this.$t('offer.clear_offer_message')
          })
        }
      })
    },
    getStockPieceByUnitID(product) {
      let rate = product?.rate ?? 1
      let rate2 = product?.rate2 ?? 1
      let productQuantity = product?.quantity ?? 1

      let amount = (rate / rate2) * productQuantity
      return `${amount} ${this.$t(`unit.${product.sales_unit_id}`)}`
    }
  }
}
</script>

<style lang="scss" scoped>
.header-navbar .navbar-container ul.navbar-nav li .media-list {
  max-height: 15rem !important;

  @media screen and (min-width: 1440px) {
    max-height: 30rem !important;
  }
}
.offer-dropdown-item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.25rem;
  .offer-dropdown-item-product-name {
    width: 100%;
  }
  .offer-dropdown-item-detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    .offer-dropdown-item-product-image {
      cursor: pointer;
      text-align: center;
      width: 20%;
    }
    .offer-dropdown-item-product-quantity {
      width: 100%;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      p {
        position: absolute;
        top: 0;
        margin-bottom: 0 !important;
        text-align: center;
        padding-left: 1rem;
        padding-right: 4.5rem;
      }

      div {
        height: 4rem;
      }
    }
    .offer-dropdown-item-product-price {
      white-space: nowrap;
      text-align: center;
      width: 30%;
    }
  }
  .offer-item-remove {
    position: absolute;
    top: 10px;
    right: 17px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>
