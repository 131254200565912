<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link class="navbar-brand" to="/">
          <h2
            class="brand-text mb-0"
            :class="{
              'text-white': skin === 'dark',
              'text-primary': skin !== 'dark',
            }"
          >
          KombiKlimaParca
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.navbar-header {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>

<script>
import { BLink, BImg } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BImg,
  },
  computed: {
    skin() {
      return this.$store.getters["appConfig/getThemeSkin"];
    },
  },
};
</script>

<style>
</style>
