let menuItems = [
  {
    route: 'Home',
    icon: 'fal fa-home',
    title: 'Home'
  },
  {
    route: 'Products',
    icon: 'fal fa-store',
    title: 'Products'
  }
]

export default menuItems
