<template>
  <div>
    <b-nav-item
      class="d-none d-md-block"
      @click="skin = isDark ? 'light' : 'dark'"
    >
      <feather-icon size="21" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />
    </b-nav-item>
    <div
      class="align-items-center d-flex d-md-none"
      @click="skin = isDark ? 'light' : 'dark'"
    >
      <feather-icon
        size="21"
        :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
        class="mr-50"
      />
      <span>{{ $t("Change Theme") }}</span>
    </div>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import { BNavItem } from "bootstrap-vue";

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig();

    const isDark = computed(() => skin.value === "dark");

    return { skin, isDark };
  },
};
</script>
