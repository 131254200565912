<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ new Date().getFullYear() }} ©
      <b-link class="ml-25" href="http://bilgecodetechnology.com" target="_blank">
        {{ appName }}
      </b-link>
      <span class="d-none d-sm-inline-block"> &nbsp;{{ $t("AllRightsReserved") }} {{ version }} </span>
    </span>

    <!-- <span class="float-md-right d-none d-md-block"
      >Hand-crafted &amp; Made with
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current"
      />
    </span> -->
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import moment from "moment";
export default {
  components: {
    BLink,
  },
  data() {
    return {
      appName: $themeConfig.app.appName,
    };
  },
  computed: {
    version() {
      let version_date = moment(document.documentElement.dataset.buildTimestampUtc);
      let today_year_diff = moment().diff(version_date, "years") + 1;
      today_year_diff = today_year_diff.toLocaleString('tr-TR',{
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      return `v${today_year_diff}.${version_date.format("MM.DD|HH.mm")}`;
    },
  },
};
</script>
