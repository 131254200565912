<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-toggler class="d-none d-lg-block" />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import Locale from "./components/Locale.vue";
import DarkToggler from "./components/DarkToggler.vue";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Locale,
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
