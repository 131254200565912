<template>
  <li
    v-if="canViewHorizontalNavMenuLink(item)"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link v-bind="linkProps" class="dropdown-item">
      <i :class="item.icon"></i>
      <span class="menu-title">{{ "paramCheck" in item ? item.title : t(item.title) }}</span>
    </b-link>
  </li>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useHorizontalNavMenuLink from "./useHorizontalNavMenuLink";
import mixinHorizontalNavMenuLink from "./mixinHorizontalNavMenuLink";

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useHorizontalNavMenuLink(props.item);

    const { t } = useI18nUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // i18n
      t,
    };
  },
};
</script>
