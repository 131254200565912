<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
      <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
      <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
    </template>
    <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" @click="changeLocale(localeObj.locale)">
      <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
      <span class="ml-50">{{ $t(localeObj.name) }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { UPDATE_LOCALE } from "@/store/services/breadcrumb-service";
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      locales: [
        {
          locale: "tr-TR",
          img: require("@/assets/images/flags/tr.png"),
          name: "Turkish",
        },
        {
          locale: "en-US",
          img: require("@/assets/images/flags/en.png"),
          name: "English",
        },
      ],
    };
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    ...mapGetters(["getLocale"]),
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
  created() {
    this.changeLocale(this.getLocale);
  },
  methods: {
    changeLocale(lang) {
      localStorage.bilgeCodeLocale = lang;
      this.$i18n.locale = lang;
      this.$store.commit(UPDATE_LOCALE, lang);
      document.title = `KombiKlimaParca - ${this.$t(this.$route.meta.title)}`;
    },
  },
};
</script>

<style></style>
